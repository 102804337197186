import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/css/common.css';
import axios from 'axios'
import qs from 'qs'
import Vant from 'vant';
import 'vant/lib/index.css';
import productionConfig from '@/config/production'
import developmentConfig from "@/config/development";
Vue.use(Vant);
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$axios = axios

const app = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')


const env = process.env.NODE_ENV
let baseURL

if (env === 'development') {
    baseURL =  'http://localhost:8088'
} else if (env === 'production') {
    baseURL =  window.location.protocol + '//' + window.location.host
} else {
    throw new Error(`Unknown environment: ${env}`);
}

axios.defaults.baseURL = baseURL
axios.defaults.crossDomain = true
axios.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('token')
        const tmpSafeCode = window.localStorage.getItem('tmpSafeCode')
        if (config.method === 'post' || config.method === 'delete') {
            if (token) {
                config.data['token'] = token
            }
            if (tmpSafeCode) {
                config.data['tmpSafeCode'] = tmpSafeCode
            }
            config.data = qs.stringify(config.data)
        } else if (config.method === 'get') {
            if (config.params == null) {
                config.params = {}
            }
            if (config.url.indexOf('nls-gateway-cn-shanghai.aliyuncs.com') === -1 && token) {
                config.params['token'] = token
            }

            if (tmpSafeCode) {
                config.params['tmpSafeCode'] = tmpSafeCode
            }

        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    resp => {
        const code = resp.data.code;
        if ([-1, -2, -3].indexOf(code) !== -1) {
            if (code === -1) {
                window.localStorage.clear()
            }
            router.push('/')
        }
        return resp
    }
)

