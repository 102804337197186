import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        appInfo: {
            appId: '',
            appName: '',
            description: '',
            url: '',
            status: 0,
            faviconURL: ''
        },
        authOkInfo: {
            title: '',
            desc: ''
        },
        netVerificationTabs: [],
        NVMenuDefaultIndex: null,
        netVerificationActiveTab: null,
        netVerificationVersionAppId: null,
        netVerificationNoticeAppId: null,
        netVerificationAppUserAppId: null,
        netVerificationPayCardAppId: null,
        netVerificationPayCardTypeId: null,
        NVFCAppId: null,
        wmsRacks: [],
        wmsWarehouseData: {},
        wmsWarehouses: [],
        wmsWarehouse: '',
        wmsVendorId: ''
    },
    getters: {
        appInfo: state => {
            return state.appInfo
        },
        authOkInfo: state => {
            return state.authOkInfo
        },
        netVerificationTabs: state => {
            return state.netVerificationTabs
        },
        netVerificationActiveTab: state => {
            return state.netVerificationActiveTab
        },
        netVerificationVersionAppId: state => {
            return state.netVerificationVersionAppId
        },
        netVerificationNoticeAppId: state => {
            return state.netVerificationNoticeAppId
        },
        netVerificationAppUserAppId: state => {
            return state.netVerificationAppUserAppId
        },
        netVerificationPayCardAppId: state => {
            return state.netVerificationPayCardAppId
        },
        netVerificationPayCardTypeId: state => {
            return state.netVerificationPayCardTypeId
        },
        NVFCAppId: state => {
            return state.NVFCAppId
        },
        wmsRacks: state => {
            return state.wmsRacks
        },
        wmsWarehouses: state => {
            return state.wmsWarehouses
        },
        wmsWarehouse: state => {
            return state.wmsWarehouse
        },
        wmsWarehouseData: state => {
            return state.wmsWarehouseData
        },
        wmsVendorId: state => {
            return state.wmsVendorId
        }
    },
    mutations: {
        updateAppInfo(state, val) {
            state.appInfo = val
        },
        updateAuthOkInfo(state, val) {
            state.authOkInfo = val
        },
        updateNetVerificationTabs(state, val) {
            const index = state.netVerificationTabs.findIndex(value => value.name === val.title)
            if (index === -1) {
                state.netVerificationTabs.push(val)
            }
        },
        removeNetVerificationTabs(state, val) {
            state.netVerificationTabs.splice(val, 1)
        },
        updateNetVerificationActiveTab(state, val) {
            state.netVerificationActiveTab = val
        },
        updateNetVerificationVersionAppId(state, val) {
            state.netVerificationVersionAppId = val
        },
        updateNetVerificationNoticeAppId(state, val) {
            state.netVerificationNoticeAppId = val
        },
        updateNetVerificationAppUserAppId(state, val) {
            state.netVerificationAppUserAppId = val
        },
        updateNetVerificationPayCardAppId(state, val) {
            state.netVerificationPayCardAppId = val
        },
        updateNetVerificationPayCardTypeId(state, val) {
            state.netVerificationPayCardTypeId = val
        },
        updateNVFCAppId(state, val) {
            state.NVFCAppId = val
        },
        updateWmsRacks(state, val) {
            state.wmsRacks = val
        },
        updateWmsWarehouses(state, val) {
            state.wmsWarehouses = val
        },
        updateWmsWarehouse(state, val) {
            state.wmsWarehouse = val
        },
        updateWmsWarehouseData(state, val) {
            state.wmsWarehouseData = val
        },
        updateWmsVendorId(state, val) {
            state.wmsVendorId = val
        }
    },
    modules: {}
})
