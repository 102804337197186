import commonAuthRouter from "@/router/common-auth-router";

const router = {
    path: '/wms',
    name: 'wms',
    component: () => import('@/views/wms/Index.vue'),
    redirect: '/wms/auth',
    children: [
        {
            path: 'auth',
            name: 'auth',
            component: () => import('@/views/wms/Auth.vue'),
            redirect: '/wms/auth/login',
            children: commonAuthRouter
        },
        {
            path: 'home',
            name: 'home',
            component: () => import('@/views/wms/Home.vue'),
            redirect: '/wms/home/warehouse',
            children: [
                {
                    path: 'overview',
                    name: 'overview',
                    component: () => import('@/views/wms/pages/DataOverview.vue')
                },
                {
                    path: 'cell-stock-analysis',
                    name: 'cell-stock-analysis',
                    component: () => import('@/views/wms/pages/CellStockAnalysis.vue')
                },
                {
                    path: 'sku-sale-analysis',
                    name: 'sku-sale-analysis',
                    component: () => import('@/views/wms/pages/SkuSaleAnalysis.vue')
                },
                {
                    path: 'warehouse',
                    name: 'warehouse',
                    component: () => import('@/views/wms/pages/Warehouse.vue')
                },
                {
                    path: 'rack/:id',
                    name: 'rack',
                    component: () => import('@/views/wms/pages/Rack.vue')
                },
                {
                    path: 'supplement',
                    name: 'supplement',
                    component: () => import('@/views/wms/pages/Supplement.vue')
                },
                {
                    path: 'supplies',
                    name: 'supplies',
                    component: () => import('@/views/wms/pages/Supplies.vue')
                },
                {
                    path: 'sync-stock',
                    name: 'sync-stock',
                    component: () => import('@/views/wms/pages/SyncStock.vue')
                },
                {
                    path: 'purchase-list',
                    name: 'purchase-list',
                    component: () => import('@/views/wms/pages/PurchaseList.vue')
                },
                {
                    path: 'purchase-sku-list',
                    name: 'purchase-sku-list',
                    component: () => import('@/views/wms/pages/PurchaseSkuList.vue')
                },
                {
                    path: 'supplier',
                    name: 'supplier',
                    component: () => import('@/views/wms/pages/Supplier.vue')
                },
                {
                    path: 'sku',
                    name: 'sku',
                    component: () => import('@/views/wms/pages/Sku.vue')
                },
                {
                    path: 'store-sku',
                    name: 'store-sku',
                    component: () => import('@/views/wms/pages/StoreSku.vue')
                },
                {
                    path: 'sku-desc',
                    name: 'sku-desc',
                    component: () => import('@/views/wms/pages/SkuDesc.vue')
                },
                {
                    path: 'realtime-order',
                    name: 'realtime-order',
                    component: () => import('@/views/wms/pages/RealtimeOrder.vue')
                },
                {
                    path: 'all-order',
                    name: 'all-order',
                    component: () => import('@/views/wms/pages/AllOrder.vue')
                },
                {
                    path: 'background-task',
                    name: 'background-task',
                    component: () => import('@/views/wms/pages/BackgroundTask.vue')
                }
            ]
        },
    ]

}
export default router