import commonAuthRouter from "@/router/common-auth-router";

const router = {
    path: '/cat-kitty',
    name: 'cat-kitty',
    component: () => import('@/views/storeLake/Index.vue'),
    redirect: '/cat-kitty/pick-up',
    children: [
        {
            path: 'pick-up',
            name: 'pick-up',
            component: () => import('@/views/catKitty/PickUp.vue'),
        }

    ]

}
export default router